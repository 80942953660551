import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import PartnersSection from '@/components/PartnersSection/PartnersSection';
import Paper from '@/components/ui/Paper/Paper';

export const Hero = styled('div')(({ theme }) => ({
  height: 700,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    minHeight: 600,
  },

  '&::before': {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `no-repeat top url("/images/hero-desktop.png")`,
    opacity: 0.7,
    content: '""',
    zIndex: -1,

    [theme.breakpoints.down('sm')]: {
      background: 'no-repeat top url("/images/hero-tablet.png")',
    },

    [theme.breakpoints.down(360)]: {
      background: 'no-repeat top url("/images/hero-mobile.png")',
    },
  },
}));

export const HeroContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  paddingLeft: theme.pageMarginHorizontal,
  paddingRight: theme.pageMarginHorizontal,

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.pageMarginHorizontalMobile,
    paddingRight: theme.pageMarginHorizontalMobile,
  },
}));

export const Heading = styled(Typography)(() => ({
  maxWidth: 650,
}));

export const ContactPerson = styled(Paper)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2, 4, 2),
  minWidth: 264,

  marginTop: 72,
}));

export const ContactPersonImage = styled('div')(() => ({
  position: 'absolute',
  top: -90,
  height: '100%',
  width: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: -1,
}));

export const Flags = styled('div')(() => ({
  position: 'absolute',
  top: -70,
  right: 13,
}));

export const PhoneWrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[200],
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  boxShadow:
    '0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
}));

export const Phone = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.blue,
  display: 'flex',
  gap: theme.spacing(1),
}));

export const Email = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.common.blue,
}));

export const Partners = styled(PartnersSection)(({ theme }) => ({
  width: '100%',
  marginTop: 'auto',

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4.5),
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4.5),
  },
}));

export const AboutSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'repeat(2, auto)',
    padding: theme.spacing(8, 12, 7),
    rowGap: theme.spacing(11),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5, 1, 1),
  },
}));

export const BecomePartner = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.blue,
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    '&::before': {
      position: 'absolute',
      left: 0,
      top: `-${theme.spacing(5.5)}`,
      content: '""',
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.divider,
    },
  },
}));

export const BecomePartnerLink = styled('a')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const BecomePartnerButton = styled(Paper)(({ theme }) => ({
  width: 211,
  height: 36,
  backgroundColor: theme.palette.grayscale[100],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.blue,
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: theme.spacing(22),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4.5),
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(7),
  },
}));

export const CTALink = styled('a')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const CTA = styled(Paper, {
  shouldForwardProp: prop => prop !== 'callTo',
})(({ theme, callTo }) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 1.5),
  backgroundColor: callTo
    ? theme.palette.primary.light
    : theme.palette.secondary.main,
  width: 394,
  height: 48,
  display: callTo ? 'none' : 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: callTo ? theme.palette.primary.main : theme.palette.text,

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
  },
}));

export const FeaturesSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4.5),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1.5),
  },
}));

export const FeaturesSeactionItem = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  minHeight: 270,
  padding: theme.spacing(1.5),

  [theme.breakpoints.down('md')]: {
    minHeight: 220,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const FeaturesSectionIconWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(7),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(0),
  },
}));

export const FeaturesSectionText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  textAlign: 'center',
}));

export const FeaturesSectionMachineImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -53,
  right: -455,

  '&:first-of-type': {
    top: -70,
    left: -348,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const FeatureSectionBestOfferInner = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const ProductsSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  a: {
    // products' links must not be clickable
    pointerEvents: 'none',
  },

  [theme.breakpoints.only('sm')]: {
    marginTop: theme.spacing(7),
  },
}));
