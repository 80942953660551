import { Box, Grid, Stack, Typography } from '@mui/material';
import Head from 'next/head';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import BestOfferIcon from '@/assets/best-offer.svg';
import EasyIcon from '@/assets/easy.svg';
import KlickrentLogo from '@/assets/logo.svg';
import OneContactIcon from '@/assets/one-contact.svg';
import ProfileIcon from '@/assets/profile.svg';
import SpeedIcon from '@/assets/speed.svg';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import PageTopMarginWrapper from '@/components/ui/PageTopMarginWrapper/PageTopMarginWrapper';
import ProductCatalog from '@/components/ui/ProductCatalog/ProductCatalog';
import Icon from '@/components/ui/SvgIcon/SvgIcon';
import {
  trackBecomePartnerClick,
  trackBottomCTAButtonEmailClick,
  trackContactBoxEmailClick,
  trackContactBoxPhoneClick,
} from '@/helpers/dataLayer';
import { generateHreflangLinks } from '@/helpers/hreflang';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import SimplifiedLayout from '@/layouts/simplified';

import {
  AboutSection,
  BecomePartner,
  BecomePartnerButton,
  BecomePartnerLink,
  ButtonWrapper,
  ContactPerson,
  ContactPersonImage,
  CTA,
  CTALink,
  Email,
  FeatureSectionBestOfferInner,
  FeaturesSeactionItem,
  FeaturesSection,
  FeaturesSectionIconWrapper,
  FeaturesSectionMachineImage,
  FeaturesSectionText,
  Flags,
  Heading,
  Hero,
  HeroContent,
  Partners,
  Phone,
  PhoneWrapper,
  ProductsSection,
} from './LanguageSpecificLandingPage.style';
import britishFlag from './lib/british-flag.png';
import germanFlag from './lib/german-flag.png';
import polishFlag from './lib/polish-flag.png';
import scissorLiftImage from './lib/scissor-lift.png';
import walzenbaggerImage from './lib/walzenbagger.png';

const getPartners = altTexts => [
  {
    id: 1,
    attributes: {
      alternativeText: altTexts.algeco,
      url:
        '/uploads/040abb00_8038_42fa_9dc3_007691b2fb81_algeco_4779b59997.png',
      width: 300,
      height: 112,
    },
  },
  {
    id: 2,
    attributes: {
      alternativeText: altTexts.ruthmann,
      url:
        '/uploads/386d4b04_60e9_4623_b32f_816cc66a0711_ruthmann_f_21cb0f365a.png',
      width: 206,
      height: 112,
    },
  },
  {
    id: 3,
    attributes: {
      alternativeText: altTexts.caterpillar,
      url: '/uploads/bc3bcca2_e624_4a61_aad7_e5367d2fc1e5_cat_f_6fe17882ee.png',
      width: 112,
      height: 112,
    },
  },
  {
    id: 4,
    attributes: {
      alternativeText: altTexts.merlo,
      url:
        '/uploads/f0177785_0911_4cc8_ad73_fdb4f826e4a6_merlo_f_5e821f87ef.png',
      width: 278,
      height: 112,
    },
  },
  {
    id: 5,
    attributes: {
      alternativeText: altTexts.volvo,
      url:
        '/uploads/2530e92a_4a57_4594_ba69_34a22a768f5d_volvo_f_71e64ab0f7.png',
      width: 236,
      height: 112,
    },
  },
];

const getProducts = labels => [
  {
    id: 39,
    name: labels.cranes.name,
    space: 1,
    image: {
      data: {
        id: 391,
        attributes: {
          width: 546,
          height: 880,
          alternativeText: labels.cranes.alt,
          url:
            '/uploads/01048d8e_134b_4dbb_97cc_ea4fcf0dbccf_krane_402x_fc67311d6a.png',
        },
      },
      link: '/',
    },
  },
  {
    id: 40,
    name: labels.containers.name,
    space: 1,
    image: {
      data: {
        id: 392,
        attributes: {
          width: 546,
          height: 880,
          alternativeText: labels.cranes.alt,
          url:
            '/uploads/8c5d3944_ad2a_4300_bb38_b60ba37650d3_buerocontainer_wohncontainer_f6c5ebac7d.png',
        },
      },
      link: '/',
    },
  },
  {
    id: 41,
    name: labels.powerAndLighting.name,
    space: 1,
    image: {
      data: {
        id: 393,
        attributes: {
          width: 546,
          height: 880,
          alternativeText: labels.powerAndLighting.alt,
          url:
            '/uploads/10bae05b_d132_400b_8dce_1e439b2936c3_lichtmasten_cf325f5250.png',
        },
      },
      link: '/',
    },
  },
  {
    id: 42,
    name: labels.refrigerationAndHeating.name,
    space: 1,
    image: {
      data: {
        id: 394,
        attributes: {
          width: 546,
          height: 880,
          alternativeText: labels.refrigerationAndHeating.alt,
          url:
            '/uploads/e39e8a43_0478_4461_83a7_f5ebf42d6bb4_heizung_402x_c3fa74e74e.png',
        },
      },
      link: '/',
    },
  },
];

const LanguageSpecificLandingPage = ({ data }) => {
  const mobileMediaQuery = useMobileMediaQuery();

  const products = useMemo(
    () => getProducts(data.productCatalog.productsLabels),
    [data.productCatalog.productsLabels]
  );
  const partners = useMemo(() => getPartners(data.partnersAltTexts), [
    data.partnersAltTexts,
  ]);

  return (
    <SimplifiedLayout>
      <Head>
        <title>{data.meta.title}</title>
        <meta key="title" name="title" content={data.meta.title} />
        <meta
          key="description"
          name="description"
          content={data.meta.description}
        />
        <meta property="og:title" content={data.meta.title} key="og:title" />
        <meta
          property="og:description"
          content={data.meta.description}
          key="og:description"
        />
        {generateHreflangLinks()}
      </Head>
      <PageTopMarginWrapper navigationHidden>
        <Hero>
          <HeroContent>
            <Stack alignItems="center">
              <Heading
                align="center"
                component="h1"
                variant={mobileMediaQuery ? 'h2' : 'h1'}
                mb={9}>
                {data.heading1}
              </Heading>
              <Grid container justifyContent="space-evenly" rowSpacing={12}>
                {data.contactPersons.map(contactPerson => (
                  <Grid item md="auto" key={contactPerson.id}>
                    <ContactPerson>
                      <ContactPersonImage>
                        <Image
                          src={contactPerson.personImage}
                          alt={contactPerson.personImageAltText}
                          width={264}
                          height={100}
                        />
                      </ContactPersonImage>
                      <Flags>
                        <Stack gap={0.5}>
                          <Image
                            src={polishFlag}
                            height={12}
                            width={12}
                            alt={contactPerson.flagsAltTexts.pl}
                          />
                          <Image
                            src={britishFlag}
                            height={12}
                            width={12}
                            alt={contactPerson.flagsAltTexts.en}
                          />
                          <Image
                            src={germanFlag}
                            height={12}
                            width={12}
                            alt={contactPerson.flagsAltTexts.de}
                          />
                        </Stack>
                      </Flags>
                      <Stack alignItems="center">
                        <Typography variant="body2" sx={{ fontSize: 20 }}>
                          {contactPerson.name}
                        </Typography>
                        <Typography
                          variant="label"
                          color="grayscale.600"
                          mb={1}>
                          {contactPerson.heading}
                        </Typography>
                        <PhoneWrapper>
                          <Phone
                            component="a"
                            variant="body2"
                            href={contactPerson.phone.href}
                            className="mrkt-intl_phone"
                            onClick={trackContactBoxPhoneClick}>
                            <Icon name="phone" />
                            {contactPerson.phone.number}
                          </Phone>
                        </PhoneWrapper>
                        <Typography variant="label" align="center">
                          {contactPerson.hours}
                        </Typography>
                        <Email
                          component="a"
                          href={contactPerson.email.href}
                          variant="label"
                          className="mrkt-intl_requestmail"
                          onClick={trackContactBoxEmailClick}>
                          {contactPerson.email.email}
                        </Email>
                      </Stack>
                    </ContactPerson>
                  </Grid>
                ))}
              </Grid>
              <Partners logos={partners} />
            </Stack>
          </HeroContent>
        </Hero>
        <Box overflow="hidden">
          <PageMargins>
            <Stack>
              <AboutSection>
                <div>
                  <Typography variant="h4" mb={2.5}>
                    {data.about.heading}
                  </Typography>
                  <Typography variant="body1">{data.about.body}</Typography>
                </div>
                <BecomePartner elevation={0}>
                  <Stack alignItems="center">
                    <KlickrentLogo />
                    <Typography variant="h4" mt={2}>
                      {data.becomePartner.heading}
                    </Typography>
                    <Typography variant="body2" mt={4}>
                      {data.becomePartner.body}
                    </Typography>
                    <BecomePartnerLink
                      href={data.becomePartner.cta.href}
                      className="mrkt-intl_partnermail"
                      onClick={trackBecomePartnerClick}>
                      <BecomePartnerButton color="primaryLight">
                        <Typography variant="body2">
                          {data.becomePartner.cta.text}
                        </Typography>
                      </BecomePartnerButton>
                    </BecomePartnerLink>
                  </Stack>
                </BecomePartner>
              </AboutSection>
              <FeaturesSection>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={4} xs={6}>
                    <FeaturesSeactionItem elevation={1}>
                      <FeaturesSectionIconWrapper>
                        <EasyIcon />
                      </FeaturesSectionIconWrapper>
                      <FeaturesSectionText variant="h4" component="p">
                        {data.features.easy}
                      </FeaturesSectionText>
                    </FeaturesSeactionItem>
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <FeaturesSeactionItem elevation={1}>
                      <FeaturesSectionIconWrapper>
                        <OneContactIcon />
                      </FeaturesSectionIconWrapper>
                      <FeaturesSectionText variant="h4" component="p">
                        {data.features.oneContact}
                      </FeaturesSectionText>
                    </FeaturesSeactionItem>
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <FeaturesSeactionItem elevation={1}>
                      <FeaturesSectionIconWrapper>
                        <ProfileIcon />
                      </FeaturesSectionIconWrapper>
                      <FeaturesSectionText variant="h4" component="p">
                        {data.features.competence}
                      </FeaturesSectionText>
                    </FeaturesSeactionItem>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={6}
                    sx={{ display: { xs: 'none', sm: 'initial' } }}>
                    <FeaturesSeactionItem elevation={1}>
                      <FeaturesSectionIconWrapper>
                        <SpeedIcon />
                      </FeaturesSectionIconWrapper>
                      <FeaturesSectionText variant="h4" component="p">
                        {data.features.availability}
                      </FeaturesSectionText>
                    </FeaturesSeactionItem>
                  </Grid>
                  <Grid item md={12} sm={6} xs={6}>
                    <FeaturesSeactionItem elevation={1}>
                      <FeatureSectionBestOfferInner>
                        <FeaturesSectionMachineImage>
                          <Image src={scissorLiftImage} alt="" />
                        </FeaturesSectionMachineImage>
                        <FeaturesSectionMachineImage>
                          <Image src={walzenbaggerImage} alt="" />
                        </FeaturesSectionMachineImage>
                        <FeaturesSectionIconWrapper>
                          <BestOfferIcon />
                        </FeaturesSectionIconWrapper>
                        <FeaturesSectionText variant="h4" component="p">
                          {data.features.bestOffer}
                        </FeaturesSectionText>
                      </FeatureSectionBestOfferInner>
                    </FeaturesSeactionItem>
                  </Grid>
                </Grid>
              </FeaturesSection>
              <ProductsSection>
                <ProductCatalog
                  headline={data.productCatalog.headline}
                  description={data.productCatalog.description}
                  products={products}
                />
              </ProductsSection>
            </Stack>
            <ButtonWrapper>
              <CTALink
                href={data.bottomCTA.email.href}
                className="mrkt-intl_requestmail"
                onClick={trackBottomCTAButtonEmailClick}>
                <CTA>
                  <Typography variant="body2">
                    {data.bottomCTA.email.text}
                  </Typography>
                </CTA>
              </CTALink>
              <CTALink
                href={data.bottomCTA.call.href}
                className="mrkt-intl_phone">
                <CTA callTo>
                  <Typography variant="body2">
                    {data.bottomCTA.call.text}
                  </Typography>
                </CTA>
              </CTALink>
            </ButtonWrapper>
          </PageMargins>
        </Box>
      </PageTopMarginWrapper>
    </SimplifiedLayout>
  );
};

LanguageSpecificLandingPage.propTypes = {
  data: PropTypes.shape({
    meta: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    heading1: PropTypes.node.isRequired,
    contactPersons: PropTypes.arrayOf(
      PropTypes.shape({
        personImageAltText: PropTypes.string.isRequired,
        flagsAltTexts: PropTypes.shape({
          pl: PropTypes.string.isRequired,
          en: PropTypes.string.isRequired,
          de: PropTypes.string.isRequired,
        }).isRequired,
        heading: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.shape({
          number: PropTypes.string,
          href: PropTypes.string,
        }).isRequired,
        hours: PropTypes.node.isRequired,
        email: PropTypes.shape({
          email: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
    partnersAltTexts: PropTypes.shape({
      algeco: PropTypes.string.isRequired,
      ruthmann: PropTypes.string.isRequired,
      caterpillar: PropTypes.string.isRequired,
      merlo: PropTypes.string.isRequired,
      volvo: PropTypes.string.isRequired,
    }).isRequired,
    about: PropTypes.shape({
      heading: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
    }).isRequired,
    becomePartner: PropTypes.shape({
      heading: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
      cta: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    features: PropTypes.shape({
      easy: PropTypes.node.isRequired,
      oneContact: PropTypes.node.isRequired,
      competence: PropTypes.node.isRequired,
      availability: PropTypes.node.isRequired,
      bestOffer: PropTypes.node.isRequired,
    }).isRequired,
    productCatalog: PropTypes.shape({
      headline: PropTypes.node.isRequired,
      description: PropTypes.node.isRequired,
      productsLabels: PropTypes.shape({
        cranes: PropTypes.shape({
          name: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
        containers: PropTypes.shape({
          name: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
        powerAndLighting: PropTypes.shape({
          name: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
        refrigerationAndHeating: PropTypes.shape({
          name: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    bottomCTA: PropTypes.shape({
      email: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }).isRequired,
      call: PropTypes.shape({
        text: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default LanguageSpecificLandingPage;
